<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="categoria.nombre"
            label="Nombre"
            :rules="[rules.req]"
            class="v-superdense-input"
            append-icon="mdi-graph-outline"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="categoria.idCategoria" @click.stop="categoria = {}">
							<v-icon left>mdi-close</v-icon>
												Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ categoria.idCategoria ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { req } from "@/utils/validations.js";

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      default: {
        nombre : null,
      },
      categoria: {
        ...this.value,
      },
      rules: {
        req,
      },
    };
  },
  methods: {
    async save() {
      if(!this.categoria.nombre || this.categoria.nombre.length == 0) this.categoria.nombre = this.default.nombre;
      if (!this.$refs.form.validate()) return;
      console.log(this.categoria);
      await axios({
        method: this.categoria.idCategoria ? "PUT" : "POST",
        url: `/categorias/${String(
          this.categoria.idCategoria ? this.categoria.idCategoria : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.categoria,
        },
      });
      this.categoria = {}
      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>